<!--
 * @Descripttion: 支付接收通知页面
 * @version:
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2020-09-20 11:02:23
 * @LastEditors: Niklaus
 * @LastEditTime: 2020-09-20 14:44:36
-->
<template>
    <div>
        <p>接收微信支付通知页面</p>
    </div>
</template>

<script>
import { RadioGroup, Radio, Field, Button } from "vant"
export default {
    data() {
        return {

        }
    },
    components: {
        [Field.name]: Field,
        [Radio.name]: Radio,
        [Button.name]: Button,
        [RadioGroup.name]: RadioGroup,
    },

    watch: {

    },

    methods: {

    },
}
</script>

<style scoped>
.warp {
    flex-wrap: wrap;
}
</style>